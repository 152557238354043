
import { Component, Vue } from "vue-property-decorator";

import * as ApiServices from '@/services/ApiServices';
import { ROOT_ACTIONS } from "@/store/actions";

import { ApiKey } from '@/types'
import { format } from "date-fns";

@Component({
  components: {
    CreateApiKey: () => import('@/components/dialogs/CreateApiKey.vue')
  }
})
export default class Index extends Vue {
  apiKeys = [] as ApiKey[]

  loading = {
    apiKeys: true,
  }

  dialogs = {
    createApiKey: false,
  }

  mounted() {
    this.fetchApiKeys();
  }

  async fetchApiKeys() {
    try {
      this.apiKeys = await ApiServices.ListApiKeys();
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    } finally {
      this.loading.apiKeys = false;
    }
  }

  formatDate(d: string | Date): string {
    return format(new Date(d), "dd-MM-yyyy hh:mm:ss");
  }
}
